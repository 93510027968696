import axios from 'axios';
import config from '@/config/env-constants';


export default {
    baseUrl: 'https://us-central1-ayun-quantity-' + config.currEnv + '.cloudfunctions.net',

    getBillingReports(filterBy, view, currUserId) {
        const url = `${this.baseUrl}/getBillingReports`;
        return axios.post(url, {
            view: view,
            currUserId: currUserId,
            filterBy: JSON.stringify(filterBy)
        });
    },

    saveBillingReport(billingReport, currUserId, currTimeStamp) {
        const url = `${this.baseUrl}/saveBillingReport`;
        return axios.post(url, {
            currUserId: currUserId,
            currTimeStamp: currTimeStamp,
            billingReport: JSON.stringify(billingReport)
        });
    }
}