// excelUtil.js
const ExcelUtil = {
    increment(char, q) {
      const charCode = char.charCodeAt(0);
      const increasedCharCode = charCode + q;
      const increasedChar = String.fromCharCode(increasedCharCode);
  
      return increasedChar;
    },
  
    setCell(cell, text, font = null, alignment = null) {
      cell.value = text;
      if (font)
        cell.font = font;

      if (alignment)
        cell.alignment = alignment;
    },

    setBorder(cell, corner, format) {
        if (!cell.border) {
            cell.border = {};
        }
        cell.border[corner] = format;
    },
  };
  
  module.exports = ExcelUtil;
  