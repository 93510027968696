<template>
    <span>
        <span v-if="billingReport.chargeType === 'Rental'">
            <b-badge variant="success">{{ billingReport.chargeType }}</b-badge>
        </span>
        <span v-else-if="billingReport.chargeType === 'Trucking'">
            <b-badge variant="warning">{{ billingReport.chargeType }}</b-badge>
        </span>
        <span v-else-if="billingReport.chargeType === 'BER'">
            <b-badge variant="danger">{{ billingReport.chargeType }}</b-badge>
        </span>
        <span v-else>
            <b-badge variant="primary">{{ billingReport.chargeType }}</b-badge>
        </span>
    </span>
</template>

<script>
export default {
    name: 'charge-type-status',
    props: {
        billingReport: {
            type: Object,
            required: true
        }
    }
};
</script>

