
// Utils
import { BillingReportUtil } from '@/utils/billingReportUtil';
import { DateUtil } from "@/utils/dateutil";
import { UserUtil } from "@/utils/userutil";

// Others
import _ from 'lodash';


function getHeaderDetails(billingReport, allCompaniesObj) {
  let assetOwnerId = billingReport.assetOwnerId;
  let assetOwnerObj = allCompaniesObj[assetOwnerId] ? allCompaniesObj[assetOwnerId] : {};
  let assetOwner = assetOwnerObj.name ? assetOwnerObj.name : '';
  let address = assetOwnerObj.address ? assetOwnerObj.address : '';
  let contactNo = assetOwnerObj.contactNo ? assetOwnerObj.contactNo : '';
  let logoFileName = assetOwnerObj.companyLogo && assetOwnerObj.companyLogo.name ? assetOwnerObj.companyLogo.name : '';
  let logoUrl = assetOwnerObj.companyLogo && assetOwnerObj.companyLogo.url ? assetOwnerObj.companyLogo.url : '';

  return {
    assetOwner: assetOwner,
    address: address,
    contactNo: contactNo,
    logoFileName: logoFileName,
    logoUrl: logoUrl
  }
}

function getBillingReportDetails(billingReport, allCompaniesObj) {
  let sectionHeader = 'BILLING REPORT ' + billingReport.billingReportNo;

  // Account Details
  let accountNo = billingReport.clientAccountNo ? billingReport.clientAccountNo : '';
  let principal = billingReport.principal ? billingReport.principal : '';
  let clientId = billingReport.clientId ? billingReport.clientId : '';
  let clientObj = allCompaniesObj[clientId] ? allCompaniesObj[clientId] : {};
  let client = clientObj.name ? clientObj.name : '';
  let address = clientObj.address ? clientObj.address : '';

  // Charge Type Details
  let chargeType = billingReport.chargeType;
  let chargeTypeDetails = billingReport.chargeTypeDetails ? billingReport.chargeTypeDetails : {};

  let chargeTypeLabels = [];
  let chargeTypeValues = [];
  if (chargeType === 'Rental') {
    let assetType = chargeTypeDetails.assetType ? chargeTypeDetails.assetType : '';
    let rentRate = chargeTypeDetails.rentRate ? formatMoneyValue(chargeTypeDetails.currency, chargeTypeDetails.rentRate) : '';
    let rentFrequency = chargeTypeDetails.rentFrequency ? chargeTypeDetails.rentFrequency : '';

    chargeTypeLabels = ['Charge Type', 'Asset Type', 'Rent Rate', 'Rent Frequency'];
    chargeTypeValues = [chargeType, assetType, rentRate, rentFrequency];

  } else if (chargeType === 'Trucking') {
    chargeTypeLabels = ['Charge Type'];
    chargeTypeValues = [chargeType];

  } else {
    let assetType = chargeTypeDetails.assetType ? chargeTypeDetails.assetType : '';
    let vatExclusiveRate = chargeTypeDetails.vatExclusiveRate ? formatMoneyValue(chargeTypeDetails.currency, chargeTypeDetails.vatExclusiveRate) : '';

    chargeTypeLabels = ['Charge Type', 'Asset Type', 'VAT EX Rate'];
    chargeTypeValues = [chargeType, assetType, vatExclusiveRate];
  }

  // Billing Details
  let billingNumber = billingReport.billingNumber ? billingReport.billingNumber : '';
  let startDate = billingReport.startDate ? DateUtil.getFormattedDateShorten(billingReport.startDate) : '';
  let endDate = billingReport.endDate ? DateUtil.getFormattedDateShorten(billingReport.endDate) : '';
  let dueDate = billingReport.dueDate ? DateUtil.getFormattedDateShorten(billingReport.dueDate) : '';

  return {
    sectionHeader: sectionHeader,
    accountDetails: [accountNo, client, principal, address],
    chargeTypeLabels: chargeTypeLabels,
    chargeTypeValues: chargeTypeValues,
    billingDetails: [billingNumber, startDate, endDate, dueDate]
  }
}

function getAdjustmentsFromAssetMovement(billingReport) {
  let chargeTypeDetails = billingReport.chargeTypeDetails ? billingReport.chargeTypeDetails : {};

  let startDate = billingReport.startDate ? DateUtil.getFormattedDateShorten(billingReport.startDate) : '';
  let endDate = billingReport.endDate ? DateUtil.getFormattedDateShorten(billingReport.endDate) : '';
  let startingQuantity = chargeTypeDetails.startingQuantity ? formatNumber(chargeTypeDetails.startingQuantity) : 0;
  let endingQuantity = chargeTypeDetails.endingQuantity ? formatNumber(chargeTypeDetails.endingQuantity) : 0;
  let startingDays = chargeTypeDetails.startingDays ? formatNumber(chargeTypeDetails.startingDays) : 0;
  let endingDays = chargeTypeDetails.endingDays ? formatNumber(chargeTypeDetails.endingDays) : 0;

  let tableColumns = getAssetMovementTableColumns(billingReport);
  let labelForThePeriod = getLabelForThePeriod(chargeTypeDetails.rentFrequency);
  let transactions = processTransactionRow(billingReport);

  return {
    startDate: startDate,
    endDate: endDate,
    startingQuantity,
    endingQuantity,
    startingDays,
    endingDays,
    transactions,
    tableColumns,
    labelForThePeriod
  }
}

function getAssetMovementTableColumns(billingReport) {
  let chargeTypeDetails = billingReport.chargeTypeDetails ? billingReport.chargeTypeDetails : {};

  if (billingReport.chargeType === 'Rental') {
    if (chargeTypeDetails.rentFrequency === 'Per Day') {
      return ['Date', 'Dispatch No.', 'Notes', '', 'Type', 'Quantity', 'Calendar Days', 'Total Asset Days'];
    } else {
      return ['Date', 'Dispatch No.', 'Notes', '', 'Type', 'Quantity', 'Hours', 'Total Asset Hours'];
    }

  } else if (billingReport.chargeType === 'Trucking') {
    return ['Date', 'Dispatch No.', 'Type', 'Truck Type', 'Plate No./Conduction No.', '', 'Billable', 'VAT EX Rate'];
  } else {
    return ['Date', 'Dispatch No.', 'Notes', '', 'Type', 'Quantity', 'Total ' + billingReport.chargeType, 'VAT EX Amount'];
  }
}

function getLabelForThePeriod(rentFrequency) {
  if (rentFrequency === 'Per Day') {
    return 'Total Asset Days for the Period';
  } else if (rentFrequency === 'Per Hour') {
    return 'Total Asset Hours for the Period';
  }
  return '';
}

function processTransactionRow(billingReport) {
  let chargeTypeDetails = billingReport.chargeTypeDetails ? billingReport.chargeTypeDetails : {};
  let transactions = billingReport.transactions ? billingReport.transactions : [];

  let items = [];
  _.forEach(transactions, transaction => {
    let dateReceived = DateUtil.getFormattedDateShorten(transaction.dateReceived);
    let dispatchNo = transaction.dispatchNo;
    let notes = transaction.notes;
    let type = transaction.type;

    if (billingReport.chargeType === 'Rental') {
      let transactionQty = type === 'Hire' ? transaction.quantity : -1 * transaction.quantity;
      let quantity = formatNumber(transactionQty);
      let calendarDays = formatNumber(transaction.calendarDays);
      let totalDays = formatNumber(transaction.totalDays);

      items.push([dateReceived, dispatchNo, notes, "", type, quantity, calendarDays, totalDays]);

    } else if (billingReport.chargeType === 'Trucking') {
      let truckType = transaction.truckType;
      let plateNo = transaction.transportation.plateNo;
      let billable = transaction.billable ? 'YES' : 'NO';
      let vatExclusiveRate = transaction.vatExclusiveRate ? formatMoneyValue(chargeTypeDetails.currency, transaction.vatExclusiveRate) : formatMoneyValue(chargeTypeDetails.currency, 0);

      items.push([dateReceived, dispatchNo, type, truckType, plateNo, "", billable, vatExclusiveRate]);

    } else {
      let quantity = transaction.quantity;
      let classifiedColumn = 'total' + billingReport.chargeType;
      let classifiedValue = transaction[classifiedColumn] ? formatNumber(transaction[classifiedColumn]) : '';
      let vatExclusiveAmount = transaction.vatExclusiveAmount ? formatMoneyValue(chargeTypeDetails.currency, transaction.vatExclusiveAmount) : formatMoneyValue(chargeTypeDetails.currency, 0);

      items.push([dateReceived, dispatchNo, notes, "", type, quantity, classifiedValue, vatExclusiveAmount]);
    }
  });

  return items;
}

function getOtherAdjustments(billingReport) {
  let adjustments = billingReport.adjustments ? billingReport.adjustments : [];
  let currency = getCurrency(billingReport);

  let items = [];
  _.forEach(adjustments, adjustment => {
    let type = adjustment.type;
    let remarks = adjustment.remarks;
    let adjustmentAmt = type === 'Additional' ? adjustment.amount : -1 * adjustment.amount;
    let amount = formatMoneyValue(currency, adjustmentAmt);

    items.push([type, remarks, '', '', '', '', '', amount]);
  });

  return {
    adjustments: items
  }
}

function getBillingSummary(billingReport) {
  let totalChargeTypeAmount = billingReport.totalChargeTypeAmount ? billingReport.totalChargeTypeAmount : 0;
  let totalAdjustmentAmount = billingReport.totalAdjustmentAmount ? billingReport.totalAdjustmentAmount : 0;
  let totalVATExclusiveAmount = billingReport.totalVATExclusiveAmount ? billingReport.totalVATExclusiveAmount : 0;
  let total12PercentVATAmount = billingReport.total12PercentVATAmount ? billingReport.total12PercentVATAmount : 0;
  let totalVATInclusiveAmount = billingReport.totalVATInclusiveAmount ? billingReport.totalVATInclusiveAmount : 0;

  let currency = getCurrency(billingReport);
  return {
    totalChargeTypeAmount: formatMoneyValue(currency, totalChargeTypeAmount),
    totalAdjustmentAmount: formatMoneyValue(currency, totalAdjustmentAmount),
    totalVATExclusiveAmount: formatMoneyValue(currency, totalVATExclusiveAmount),
    total12PercentVATAmount: formatMoneyValue(currency, total12PercentVATAmount),
    totalVATInclusiveAmount: formatMoneyValue(currency, totalVATInclusiveAmount),
  }
}

function getSignatories(billingReport, allUsersObj) {
  let preparedBy = billingReport.createdBy ? billingReport.createdBy : '';
  let preparedByName = preparedBy.length > 0 ? UserUtil.getUserAbbreviatedName(allUsersObj, preparedBy) : '';

  let approvedBy = billingReport.approvedBy ? billingReport.approvedBy : '';
  let approvedByName = approvedBy.length > 0 ? UserUtil.getUserAbbreviatedName(allUsersObj, approvedBy) : '';

  return {
    preparedBy: preparedByName,
    approvedBy: approvedByName
  }
}

function getCurrency(billingReport) {
  let chargeTypeDetails = billingReport.chargeTypeDetails ? billingReport.chargeTypeDetails : {};
  return chargeTypeDetails.currency ? chargeTypeDetails.currency : 'PHP';
}

function formatMoneyValue(currency, number) {
  number = parseFloat(number);
  return BillingReportUtil.formatMoneyValue(currency, number);
}

function formatNumber(number) {
  number = parseInt(number);
  return BillingReportUtil.formatNumber(number);
}

function getTxtStyleRegular() {
  return { size: 10 };
}

function getTxtStyleBold() {
  return { size: 10, bold: true };
}

function getTxtStyleItalic() {
  return { size: 10, italic: true };
}

function getTxtAlignRight() {
  return { horizontal: 'right' }
}

function getTxtAlignLeft() {
  return { horizontal: 'left' }
}

function getTxtAlignCenter() {
  return { horizontal: 'center' }
}

function getBorderStyleThin() {
  return { style: 'thin', color: { argb: '000000' } };
}

function getAllBordersStyleThin() {
  return {
    top: { style: 'thin', color: { argb: '000000' } },
    left: { style: 'thin', color: { argb: '000000' } },
    bottom: { style: 'thin', color: { argb: '000000' } },
    right: { style: 'thin', color: { argb: '000000' } }
  };
}

function getFillStyleBlue() {
  return {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '003399' }
  };
}

function getFillStyleOrange() {
  return {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'f18f01' }
  };
}

export const ExportBillingUtil = {
  getHeaderDetails,
  getBillingReportDetails,
  getAdjustmentsFromAssetMovement,
  getOtherAdjustments,
  getBillingSummary,
  getSignatories,

  getTxtStyleRegular,
  getTxtStyleBold,
  getTxtStyleItalic,
  getTxtAlignRight,
  getTxtAlignLeft,
  getTxtAlignCenter,
  getBorderStyleThin,
  getAllBordersStyleThin,
  getFillStyleBlue,
  getFillStyleOrange,
};