<template>
    <div>
        <b class="mr-2">BILLING DETAILS</b>
        <b-button v-if="hasEdit" size="sm" v-b-tooltip.hover.top="'Update Billing Details'" variant="warning"
            @click.stop="updateBillingDetails()">
            <i class="fa fa-pencil"></i>
        </b-button>

        <div class="mt-2">
            <span class="field-label">Billing Number</span>
            <span class="field-value numFont">
                {{ billingReport.billingNumber }}
            </span>
        </div>
        <div class="mt-1">
            <span class="field-label">Start Date</span>
            <span class="field-value">
                {{ getFormattedDate(billingReport.startDate) }}
            </span>
        </div>
        <div class="mt-1">
            <span class="field-label">End Date</span>
            <span class="field-value">
                {{ getFormattedDate(billingReport.endDate) }}
            </span>
        </div>
        <div class="mt-1">
            <span class="field-label">Due Date</span>
            <span class="field-value">
                {{ getFormattedDate(billingReport.dueDate) }}
            </span>
        </div>
        <div class="mt-2" v-if="hasPreviousBillings">
            <b-link class="orange-txt" @click.stop="viewPreviousBillings()">
                <i>View Previous Billings</i>
            </b-link>
        </div>
    </div>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';

export default {
    name: 'billing-details',
    props: {
        billingReport: {
            type: Object,
            required: true,
        },
        hasEdit: {
            type: Boolean,
            required: true,
        },
        hasPreviousBillings: {
            type: Boolean,
            required: true,
        }
    },
    methods: {
        updateBillingDetails() {
            this.$emit('onUpdatePeriod', this.billingReport);
        },
        viewPreviousBillings() {
            this.$emit('onViewPreviousBillings', this.billingReport);
        },

        // UTILS
        getFormattedDate(date) {
            return DateUtil.getFormattedDate(date);
        }
    }
}
</script>

<style scoped>
.field-label {
    font-weight: bold;
    margin-right: 5px;
    margin-top: 3px;
}

.field-value {
    font-size: 16px !important;
}
</style>