<template>
    <b-card>
        <b-tabs>
            <b-tab title="Primary Information" active>
                <b-card-text>
                    <b-form-group label="Account No" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        {{ getValue(row.item, 'clientAccountNo') }}
                    </b-form-group>
                    <b-form-group label="Asset Owner" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        {{ getValue(row.item, 'assetOwner') }}
                    </b-form-group>
                    <b-form-group label="Client" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        {{ getValue(row.item, 'client') }}
                    </b-form-group>
                    <b-form-group label="Principal" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        {{ getValue(row.item, 'principal') }}
                    </b-form-group>

                    <b-form-group label="Created By" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        {{ getValue(row.item, 'createdBy') }}
                    </b-form-group>
                    <b-form-group label="Date Created" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        {{ getFormattedDateWithTime(row.item.dateCreated) }}
                    </b-form-group>

                    <span v-if="row.item.status === 'Approved'">
                        <b-form-group label="Date Approved" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ getFormattedDateWithTime(row.item.dateApproved) }}
                        </b-form-group>
                        <b-form-group label="Approved By" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ getValue(row.item, 'approvedBy') }}
                        </b-form-group>
                    </span>
                    <span v-else-if="row.item.status === 'Cancelled'">
                        <b-form-group label="Date Cancelled" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ getFormattedDateWithTime(row.item.dateCancelled) }}
                        </b-form-group>
                        <b-form-group label="Cancelled By" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ getValue(row.item, 'cancelledBy') }}
                        </b-form-group>
                    </span>
                    <span v-else-if="row.item.status === 'Rejected'">
                        <b-form-group label="Date Rejected" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ getFormattedDateWithTime(row.item.dateRejected) }}
                        </b-form-group>
                        <b-form-group label="Rejected By" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ getValue(row.item, 'rejectedBy') }}
                        </b-form-group>
                    </span>
                    <span v-else>
                        <b-form-group label="Date Updated" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ getFormattedDateWithTime(row.item.dateUpdated) }}
                        </b-form-group>
                        <b-form-group label="Updated By" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ getValue(row.item, 'updatedBy') }}
                        </b-form-group>
                    </span>

                    <b-form-group label="Status" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <BillingReportRowStatus :row="row" />
                    </b-form-group>

                    <b-form-group label="Remarks" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <span class="truncate-text">
                            <truncate collapsed-text-class="collapsed" clamp="Show More" :length="100" less="Show Less"
                                type="html" :text="getValue(row.item, 'remarks')" />
                        </span>
                    </b-form-group>
                </b-card-text>
            </b-tab>

            <b-tab title="Charge Details">
                <b-form-group label="Charge Type" label-cols-sm="3" label-class="font-weight-bold pt-0"
                    label-align-sm="right">
                    <ChargeTypeStatus :billingReport="row.item" />
                </b-form-group>

                <span v-if="row.item.chargeType === 'Rental'">
                    <b-form-group label="Asset Type" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        {{ getChargeDetailsValue(row.item, 'assetType') }}
                    </b-form-group>
                    <b-form-group label="Description" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <span class="truncate-text">
                            <truncate collapsed-text-class="collapsed" clamp="Show More" :length="100" less="Show Less"
                                type="html" :text="getChargeDetailsValue(row.item, 'description')" />
                        </span>
                    </b-form-group>
                    <b-form-group label="Contracted Quantity" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <span class="numFont">{{ getChargeDetailsValue(row.item, 'quantity').toLocaleString() }}</span>
                    </b-form-group>
                    <b-form-group label="Rent Rate" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <span class="numFont">
                            {{ formatMoneyValue(chargeTypeDetails.currency, chargeTypeDetails.rentRate) }}
                        </span>
                    </b-form-group>
                    <b-form-group label="Rent Frequency" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        {{ getChargeDetailsValue(row.item, 'rentFrequency') }}
                    </b-form-group>
                </span>
                <span v-else-if="row.item.chargeType === 'Trucking'">
                    <b-form-group label="Description" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        {{ getChargeDetailsValue(row.item, 'description') }}
                    </b-form-group>
                </span>
                <span v-else>
                    <b-form-group label="Asset Type" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        {{ getChargeDetailsValue(row.item, 'assetType') }}
                    </b-form-group>
                    <b-form-group label="VAT EX Rate" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <span class="numFont">
                            {{ formatMoneyValue(chargeTypeDetails.currency, chargeTypeDetails.vatExclusiveRate) }}
                        </span>
                    </b-form-group>
                    <b-form-group label="Description" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        {{ getChargeDetailsValue(row.item, 'description') }}
                    </b-form-group>
                </span>
            </b-tab>

            <b-tab title="Billing  Details">
                <b-form-group label="Billing No" label-cols-sm="3" label-class="font-weight-bold pt-0"
                    label-align-sm="right">
                    <span class="numFont">{{ getValue(row.item, 'billingNumber') }}</span>
                </b-form-group>
                <b-form-group label="Start Date" label-cols-sm="3" label-class="font-weight-bold pt-0"
                    label-align-sm="right">
                    {{ getFormattedDateWithTime(row.item.startDate) }}
                </b-form-group>
                <b-form-group label="End Date" label-cols-sm="3" label-class="font-weight-bold pt-0"
                    label-align-sm="right">
                    {{ getFormattedDateWithTime(row.item.endDate) }}
                </b-form-group>
                <b-form-group label="Due Date" label-cols-sm="3" label-class="font-weight-bold pt-0"
                    label-align-sm="right">
                    {{ getFormattedDateWithTime(row.item.dueDate) }}
                </b-form-group>
                <b-form-group :label="'Total ' + row.item.chargeType + ' Amount'" label-cols-sm="3"
                    label-class="font-weight-bold pt-0" label-align-sm="right">
                    <span class="numFont">
                        {{ formatMoneyValue('PHP', row.item.totalChargeTypeAmount) }}
                    </span>
                </b-form-group>
                <b-form-group label="Total Adjustments" label-cols-sm="3" label-class="font-weight-bold pt-0"
                    label-align-sm="right">
                    <span class="numFont">
                        {{ formatMoneyValue('PHP', row.item.totalAdjustmentAmount) }}
                    </span>
                </b-form-group>
                <b-form-group label="Total VAT EX Amount" label-cols-sm="3" label-class="font-weight-bold pt-0"
                    label-align-sm="right">
                    <span class="numFont">
                        {{ formatMoneyValue('PHP', row.item.totalVATExclusiveAmount) }}
                    </span>
                </b-form-group>
                <b-form-group label="12% VAT Amount" label-cols-sm="3" label-class="font-weight-bold pt-0"
                    label-align-sm="right">
                    <span class="numFont">
                        {{ formatMoneyValue('PHP', row.item.total12PercentVATAmount) }}
                    </span>
                </b-form-group>
                <b-form-group label="Total VAT IN Amount" label-cols-sm="3" label-class="font-weight-bold pt-0"
                    label-align-sm="right">
                    <span class="numFont">
                        {{ formatMoneyValue('PHP', row.item.totalVATInclusiveAmount) }}
                    </span>
                </b-form-group>
            </b-tab>
        </b-tabs>
    </b-card>
</template>

<script>
// Components
import BillingReportRowStatus from './BillingReportRowStatus.vue';
import ChargeTypeStatus from './ChargeTypeStatus';

// Utils
import { BillingReportUtil } from '@/utils/billingReportUtil';
import { DateUtil } from '@/utils/dateutil';

// Others
import truncate from 'vue-truncate-collapsed';

export default {
    name: "billing-report-details-view",
    components: {
        BillingReportRowStatus,
        ChargeTypeStatus,
        truncate
    },
    props: {
        row: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            billingReport: {}
        }
    },
    computed: {
        totalVATInclusiveAmount() {
            let totalVATInclusiveAmount = this.billingReport.totalVATInclusiveAmount;
            return totalVATInclusiveAmount ? totalVATInclusiveAmount : 0;
        },
        chargeTypeDetails() {
            return this.billingReport.chargeTypeDetails ? this.billingReport.chargeTypeDetails : {};
        }
    },
    mounted() {
        // init billingReport
        this.$store.commit('SET_CURR_BILLING_REPORT', this.row.item);
        this.billingReport = { ...this.$store.getters.currBillingReport };
    },
    methods: {
        getFormattedDateWithTime(date) {
            return DateUtil.getFormattedDateWithTime(date);
        },
        getValue(item, fieldName) {
            let billingReport = item ? item : {};
            let value = billingReport[fieldName] ? billingReport[fieldName] : "-";
            return value;
        },
        getChargeDetailsValue(item, fieldName) {
            let chargeTypeDetail = item.chargeTypeDetails ? item.chargeTypeDetails : {};
            let value = chargeTypeDetail[fieldName] ? chargeTypeDetail[fieldName] : "-";
            return value;
        },

        // UTILS
        formatMoneyValue(currency, number) {
            return BillingReportUtil.formatMoneyValue(currency, number);
        }
    }
}
</script>

<style scoped>
.field-label-main {
    font-weight: bold;
    margin-top: 3px;
    font-size: 20px;
    text-transform: uppercase;
}

.field-value-main {
    font-weight: bold;
    font-size: 20px !important;
    margin-right: 10px;
}
</style>