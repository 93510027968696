import { db } from '@/config/firebase';


async function getPreviousBillingReports(clientAccountId, chargeType, statuses, limit) {
    let reportsObj = {};

    let query = db.collection("billingReports")
        .where("clientAccountId", "==", clientAccountId)
        .where("chargeType", "==", chargeType)
        .where("status", "in", statuses)
        .orderBy("dateUpdated", "desc")
        .limit(limit);

    const querySnapshot = await query.get();
    querySnapshot.forEach((doc) => {
        let reportObj = doc.data();
        reportObj.id = doc.id;
        reportsObj[doc.id] = reportObj;
    });

    return reportsObj;
}

function generateQuery(filter) {
    let query = db.collection("billingReports");

    if (filter.fromTimestamp && filter.fromTimestamp > 0) {
        query = query.where("dueDate", ">=", filter.fromTimestamp);
    }
    if (filter.toTimestamp && filter.toTimestamp > 0) {
        query = query.where("dueDate", "<=", filter.toTimestamp);
    }
    if (filter.assetOwner && filter.assetOwner.id && filter.assetOwner.id.length > 0) {
        query = query.where("assetOwnerId", "==", filter.assetOwner.id);
    }
    if (filter.client && filter.client.id && filter.client.id.length > 0) {
        query = query.where("clientId", "==", filter.client.id);
    }
    if (filter.chargeType && filter.chargeType.length > 0) {
        query = query.where("chargeType", "==", filter.chargeType);
    }
    if (filter.status && filter.status.length > 0) {
        query = query.where("status", "==", filter.status);
    }
    if (filter.clientAccount && filter.clientAccount.accountNo && filter.clientAccount.accountNo.length > 0) {
        query = query.where("clientAccountNo", "==", filter.clientAccount.accountNo);
    }
    if (filter.billingReportNo && filter.billingReportNo.length > 0) {
        query = query.where("billingReportNo", "==", filter.billingReportNo);
    }

    return query;
}

function getReportListener(filter, callback) {
    let query = generateQuery(filter);
    let listener = query.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
            callback(change.type, change.doc.data());
        });
    });
    return listener;
}

export default {
    getPreviousBillingReports,
    getReportListener
}
