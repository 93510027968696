<template>
    <div>
        <b>ACCOUNT</b>

        <div class="mt-2">
            <span class="field-label">Account No</span>
            <span class="field-value numFont">
                {{ billingReport.clientAccountNo }}
            </span>
        </div>
        <div class="mt-1">
            <span class="field-label">Asset Owner</span>
            <span class="field-value">
                {{ billingReport.assetOwner }}
            </span>
        </div>
        <div class="mt-1">
            <span class="field-label">Client</span>
            <span class="field-value">
                {{ billingReport.client }}
            </span>
        </div>
        <div class="mt-1">
            <span class="field-label">Principal</span>
            <span class="field-value">
                {{ clientAccount.principal ? clientAccount.principal : '-' }}
            </span>
        </div>
        <div class="mt-1">
            <span class="field-label">Contract Period</span>
            <span class="field-value">
                {{ clientAccount.contractPeriod }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "account-details",
    props: {
        billingReport: {
            type: Object,
            required: true
        },
        clientAccount: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>
.field-label {
    font-weight: bold;
    margin-right: 5px;
    margin-top: 3px;
}

.field-value {
    font-size: 16px !important;
}
</style>