<template>
    <div>
        <b>CHARGE TYPE</b>

        <div v-if="billingReport.chargeType === 'Rental'">
            <div class="mt-2">
                <span class="field-label">Charge Type</span>
                <span class="field-value">
                    <ChargeTypeStatus :billingReport="billingReport" />
                </span>
            </div>
            <div class="mt-1">
                <span class="field-label">Asset Type</span>
                <span class="field-value">
                    {{ getAssetType(billingReport) }}
                </span>
            </div>
            <div class="mt-1">
                <span class="field-label">Rent Rate</span>
                <span class="field-value numFont">
                    {{ getRentRate(billingReport) }}
                </span>
            </div>
            <div class="mt-1">
                <span class="field-label">Rent Frequency</span>
                <span class="field-value">
                    {{ billingReport.chargeTypeDetails.rentFrequency }}
                </span>
            </div>
            <div class="mt-1">
                <span class="field-label">Contracted Quantity</span>
                <span class="field-value numFont">
                    {{ billingReport.chargeTypeDetails.quantity.toLocaleString() }}
                </span>
            </div>
            <div class="mt-1">
                <span class="field-label">Description</span>
                <span class="field-value">
                    <i>{{ getDescription(billingReport) }}</i>
                </span>
            </div>
        </div>

        <div v-else-if="billingReport.chargeType === 'Trucking'">
            <div class="mt-2">
                <span class="field-label">Charge Type</span>
                <span class="field-value">
                    <ChargeTypeStatus :billingReport="billingReport" />
                </span>
            </div>
            <div class="mt-1">
                <span class="field-label">Description</span>
                <span class="field-value">
                    <i>{{ getDescription(billingReport) }}</i>
                </span>
            </div>
        </div>

        <div v-else>
            <div class="mt-2">
                <span class="field-label">Charge Type</span>
                <span class="field-value">
                    <ChargeTypeStatus :billingReport="billingReport" />
                </span>
            </div>
            <div class="mt-1">
                <span class="field-label">Asset Type</span>
                <span class="field-value">
                    {{ getAssetType(billingReport) }}
                </span>
            </div>
            <div class="mt-1">
                <span class="field-label">VAT Exclusive Rate</span>
                <span class="field-value numFont">
                    {{ getVATExRate(billingReport) }}
                </span>
            </div>
            <div class="mt-1">
                <span class="field-label">Description</span>
                <span class="field-value">
                    <i>{{ getDescription(billingReport) }}</i>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
// Component
import ChargeTypeStatus from '../ChargeTypeStatus';

// Utils
import { BillingReportUtil } from '@/utils/billingReportUtil';

export default {
    name: "charge-type-details",
    components: {
        ChargeTypeStatus
    },
    props: {
        billingReport: {
            type: Object,
            required: true
        }
    },
    methods: {
        getAssetType(billingReport) {
            let chargeTypeDetails = billingReport.chargeTypeDetails ? billingReport.chargeTypeDetails : {};
            return chargeTypeDetails.assetType ? chargeTypeDetails.assetType : '-';
        },
        getCurrency(billingReport) {
            let chargeTypeDetails = billingReport.chargeTypeDetails ? billingReport.chargeTypeDetails : {};
            return chargeTypeDetails.currency ? chargeTypeDetails.currency : '-';
        },
        getRentRate(billingReport) {
            let chargeTypeDetails = billingReport.chargeTypeDetails ? billingReport.chargeTypeDetails : {};
            let rentRate = chargeTypeDetails.rentRate ? chargeTypeDetails.rentRate : 0;
            let currency = this.getCurrency(billingReport);
            return BillingReportUtil.formatMoneyValue(currency, rentRate);
        },
        getVATExRate(billingReport) {
            let chargeTypeDetails = billingReport.chargeTypeDetails ? billingReport.chargeTypeDetails : {};
            let vatExclusiveRate = chargeTypeDetails.vatExclusiveRate ? chargeTypeDetails.vatExclusiveRate : 0;
            let currency = this.getCurrency(billingReport);
            return BillingReportUtil.formatMoneyValue(currency, vatExclusiveRate)
        },
        getDescription(billingReport) {
            let chargeTypeDetails = billingReport.chargeTypeDetails ? billingReport.chargeTypeDetails : {};
            return chargeTypeDetails.description ? chargeTypeDetails.description : '-';
        }
    }
}
</script>

<style scoped>
.field-label {
    font-weight: bold;
    margin-right: 5px;
    margin-top: 3px;
}

.field-value {
    font-size: 16px !important;
}
</style>