<template>
    <div>
        <b-row class="mt-2">
            <b-col lg="6" md="6" sm="6" class="text-sm-right">
                <span class="field-label">Total {{ billingReport.chargeType }} Amount</span>
            </b-col>
            <b-col lg="6" md="6" sm="6" class="text-sm-right">
                <span class="field-value numFont">
                    {{ formatMoneyValue('PHP', totalChargeTypeAmount) }}
                </span>
            </b-col>
        </b-row>
        <b-row class="mt-2">
            <b-col lg="6" md="6" sm="6" class="text-sm-right">
                <span class="field-label">Total Adjustments**</span>
            </b-col>
            <b-col lg="6" md="6" sm="6" class="text-sm-right">
                <span class="field-value numFont">
                    {{ formatMoneyValue('PHP', totalAdjustmentAmount) }}
                </span>
            </b-col>
        </b-row>
        <b-row class="mt-2">
            <b-col lg="6" md="6" sm="6" class="text-sm-right">
                <span class="field-label">Total VAT EX Amount</span>
            </b-col>
            <b-col lg="6" md="6" sm="6" class="text-sm-right">
                <span class="field-value numFont">
                    {{ formatMoneyValue('PHP', totalVATExclusiveAmount) }}
                </span>
            </b-col>
        </b-row>
        <b-row class="mt-2">
            <b-col lg="6" md="6" sm="6" class="text-sm-right">
                <span class="field-label">12% VAT Amount</span>
            </b-col>
            <b-col lg="6" md="6" sm="6" class="text-sm-right">
                <span class="field-value numFont">
                    {{ formatMoneyValue('PHP', total12PercentVATAmount) }}
                </span>
            </b-col>
        </b-row>

        <b-row class="mt-4">
            <b-col lg="6" md="6" sm="12" class="text-sm-right">
                <span class="field-label-main">TOTAL VAT IN AMOUNT</span>
            </b-col>
            <b-col lg="6" md="6" sm="12" class="text-sm-right">
                <span class="field-value-main numFont">
                    {{ formatMoneyValue('PHP', totalVATInclusiveAmount) }}
                </span>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Utils
import { BillingReportUtil } from '@/utils/billingReportUtil';

// Others
import EventBus from '@/shared/event-bus';

export default {
    name: 'billing-computation-summary',
    props: {
        billingReport: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            billingReportId: '',
        }
    },
    computed: {
        totalChargeTypeAmount() {
            return this.billingReport.totalChargeTypeAmount ? this.billingReport.totalChargeTypeAmount : 0;
        },
        totalAdjustmentAmount() {
            return this.billingReport.totalAdjustmentAmount ? this.billingReport.totalAdjustmentAmount : 0;
        },
        totalVATExclusiveAmount() {
            return this.billingReport.totalVATExclusiveAmount ? this.billingReport.totalVATExclusiveAmount : 0;
        },
        total12PercentVATAmount() {
            return this.billingReport.total12PercentVATAmount ? this.billingReport.total12PercentVATAmount : 0;
        },
        totalVATInclusiveAmount() {
            return this.billingReport.totalVATInclusiveAmount ? this.billingReport.totalVATInclusiveAmount : 0;
        }
    },
    watch: {
        billingReportId: function (newVal) {
            if (newVal) {
                this.updateComputationSummary();
            }
        },
        'billingReport.totalChargeTypeAmount': function (newVal) {
            if (newVal) {
                this.updateComputationSummary();
            }
        },
        'billingReport.totalAdjustmentAmount': function (newVal) {
            if (newVal) {
                this.updateComputationSummary();
            }
        }
    },
    mounted() {
        this.billingReportId = this.billingReport && this.billingReport.id ? this.billingReport.id : '-';
        this.billingReport.totalChargeTypeAmount = this.billingReport && this.billingReport.totalChargeTypeAmount ? this.billingReport.totalChargeTypeAmount : 0;
        this.billingReport.totalAdjustmentAmount = this.billingReport && this.billingReport.totalAdjustmentAmount ? this.billingReport.totalAdjustmentAmount : 0;

        EventBus.$on("onUpdateChargeTypeAmount", chargeTypeAmt => {
            this.billingReport.totalChargeTypeAmount = parseFloat(chargeTypeAmt);
            this.updateComputationSummary();
        });
        EventBus.$on("onUpdateAdjustmentAmount", adjustmentAmt => {
            this.billingReport.totalAdjustmentAmount = parseFloat(adjustmentAmt);
            this.updateComputationSummary();
        });
    },
    methods: {
        updateComputationSummary() {
            this.billingReport.totalVATExclusiveAmount = this.getTotalVATExAmt();
            this.billingReport.total12PercentVATAmount = this.getTotal12PercentVATAmt();
            this.billingReport.totalVATInclusiveAmount = this.getTotalVATInAmt();
        },
        getTotalVATExAmt() {
            return this.billingReport.totalChargeTypeAmount + this.billingReport.totalAdjustmentAmount;
        },
        getTotal12PercentVATAmt() {
            return this.billingReport.totalVATExclusiveAmount * 0.12;
        },
        getTotalVATInAmt() {
            return this.billingReport.totalVATExclusiveAmount + this.billingReport.total12PercentVATAmount;
        },

        // UTILS
        formatMoneyValue(currency, number) {
            return BillingReportUtil.formatMoneyValue(currency, number);
        }
    },
    beforeDestroy() {
        EventBus.$off('onUpdateChargeTypeAmount');
        EventBus.$off('onUpdateAdjustmentAmount');
    },
}
</script>

<style scoped>
.field-label {
    font-weight: bold;
    margin-right: 5px;
    margin-top: 3px;
    font-size: 16px;
}

.field-value {
    font-size: 18px !important;
    margin-right: 10px;
}

.field-label-main {
    font-weight: bold;
    margin-top: 3px;
    font-size: 20px;
}

.field-value-main {
    font-weight: bold;
    font-size: 20px !important;
    margin-right: 10px;
}
</style>